export const USERNAME = 'avalanchecanada'
export const ACCESS_TOKEN =
    'pk.eyJ1IjoiYXZhbGFuY2hlY2FuYWRhIiwiYSI6ImNqd2dvZmUxdzE4ZWg0M2tkaXpuNG95aTQifQ.pBLM87fE3sIxRJqJT7Bf7g'

export const API = 'https://api.mapbox.com'

export const STYLE_IDS = {
    default: process.env.NEXT_PUBLIC_MAPBOX_STYLE_ID || 'ckuk675w6gwfk17pjrqae4tjy',
}

const STYLE_ROOT = 'mapbox://styles/' + USERNAME + '/'

export const STYLES = {
    default: STYLE_ROOT + STYLE_IDS.default,
}

const TILESET_ROOT = 'mapbox://' + USERNAME + '.'

export const TILESETS = {
    closureZones: TILESET_ROOT + 'ckukhj6gpaetl27pe2gfkc2dq-5cgrw',
}
